.backdropAnimation {
  position: "absolute";
  width: "100%";
  height: "100%";
  background: #fff;
  top: 0;
  right: 0;
  transform: rotate(180deg);
  animation: curlDisplayAnimation 5s linear infinite;
}

@keyframes curlDisplayAnimation {
  0% {
    width: 100%;
  }
  80% {
    width: 0;
  }
  100% {
    width: 0;
  }
}

