html,
body {
  margin: 0px;
}
.formsubmit.zcwf_button {
  color: white !important;
  background: transparent linear-gradient(0deg, #0279ff 0%, #00a3f3 100%);
}
#crmWebToEntityForm.zcwf_lblLeft {
  width: 100%;
  padding: 25px;
  margin: 0 auto;
  box-sizing: border-box;
}
#crmWebToEntityForm.zcwf_lblLeft * {
  box-sizing: border-box;
}
#crmWebToEntityForm {
  text-align: left;
}
#crmWebToEntityForm * {
  direction: ltr;
}
.zcwf_lblLeft .zcwf_title {
  word-wrap: break-word;
  padding: 0px 6px 10px;
  font-weight: bold;
}
.zcwf_lblLeft.cpT_primaryBtn:hover {
  background: linear-gradient(#02acff 0, #006be4 100%) no-repeat padding-box !important;
  box-shadow: 0 -2px 0 0 #0159b9 inset !important;
  border: 0 !important;
  color: #fff !important;
  outline: 0 !important;
}
.zcwf_lblLeft .zcwf_col_fld input[type="text"],
input[type="password"],
.zcwf_lblLeft .zcwf_col_fld textarea {
  width: 60%;
  border: 1px solid #c0c6cc !important;
  resize: vertical;
  border-radius: 2px;
  float: left;
}
.zcwf_lblLeft .zcwf_col_lab {
  width: 30%;
  word-break: break-word;
  padding: 0px 6px 0px;
  margin-right: 10px;
  margin-top: 5px;
  float: left;
  min-height: 1px;
}
.zcwf_lblLeft .zcwf_col_fld {
  float: left;
  width: 68%;
  padding: 0px 6px 0px;
  position: relative;
  margin-top: 5px;
}
.zcwf_lblLeft .zcwf_privacy {
  padding: 6px;
}
.zcwf_lblLeft .wfrm_fld_dpNn {
  display: none;
}
.zcwf_lblLeft .zcwf_col_fld_slt {
  width: 60%;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  float: left;
  resize: vertical;
  padding: 2px 5px;
}
.zcwf_lblLeft .zcwf_row:after,
.zcwf_lblLeft .zcwf_col_fld:after {
  content: "";
  display: table;
  clear: both;
}
.zcwf_lblLeft .zcwf_col_help {
  float: left;
  margin-left: 7px;
  font-size: 12px;
  max-width: 35%;
  word-break: break-word;
}
.zcwf_lblLeft .zcwf_help_icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: inline-block;
  background: #fff;
  border: 1px solid #c0c6cc;
  color: #c1c1c1;
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
  border-radius: 50%;
}
.zcwf_lblLeft .zcwf_row {
  margin: 15px 0px;
}
.zcwf_lblLeft .formsubmit {
  margin-right: 5px;
  cursor: pointer;
  color: #313949;
  font-size: 12px;
}
.zcwf_lblLeft .zcwf_privacy_txt {
  width: 90%;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Arial;
  display: inline-block;
  vertical-align: top;
  color: #313949;
  padding-top: 2px;
  margin-left: 6px;
}
.zcwf_lblLeft .zcwf_button {
  font-size: 12px;
  color: #313949;
  border: 1px solid #c0c6cc;
  padding: 3px 9px;
  border-radius: 4px;
}
.zcwf_lblLeft .formsubmit.zcwf_button {
  color: white !important;
  background: transparent linear-gradient(0deg, #0279ff 0%, #00a3f3 100%);
  box-shadow: 0 -2px 0 0 #0159b9 inset !important;
  border: 0 !important;
  color: #fff !important;
  outline: 0 !important;
}

.wf_customMessageBox {
  font-family: Arial, Helvetica, sans-serif;
  color: #132c14;
  background: #f5faf5;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  max-width: 90%;
  width: max-content;
  word-break: break-word;
  z-index: 11000;
  border-radius: 6px;
  border: 1px solid #a9d3ab;
  min-width: 100px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
}
.wf_customCircle {
  position: relative;
  background-color: #12aa67;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  flex: none;
  margin-right: 7px;
}
.wf_customCheckMark {
  box-sizing: unset !important;
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 6px;
  top: 9px;
  height: 8px;
  width: 3px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.wf_customClose {
  box-sizing: border-box;
  position: relative;
  width: 18px;
  height: 18px;
}
.wf_customClose::after,
.wf_customClose::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 1.5px;
  background: #616e88;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px;
}
.wf_customClose::after {
  transform: rotate(-45deg);
}
