.root {
  /* padding: 45px 32px; */
  /* border: 1px solid ${StyleConfig.mainThemeColor}; */
  position: relative;
}

.card {
  width: 100%;
  height: 100%;
  min-height: 200px;
}

.thumbnailImage {
  width: 100%;
}

.videoTitle {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  padding: 5px;
}

.modal {
  border: none;
  outline: none;
}

.videoContainer {
  width: 80%;
  height: 80%;
  margin: auto;
  margin-top: 5%;
  border: none;
  outline: none;
}
